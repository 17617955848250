import VerticalEnter from "../components/VerticalEnter";
import { useContext, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage } from "react-konva";
import Konva from "konva";
import {AuthContext} from "../App";

export default function VerticalScene(){
    const { state,dispatch } = useContext(AuthContext);
    document.title = "玩心奥利奥元宇宙";

    const scale =  window.innerWidth / 750;
    const ratio = 750 / 1624;
    const videoMid = useRef();
    const animClicked = useRef();

    const [loaded, setLoaded] = useState(false);
    const [entered, setEntered] = useState(false);
    const [lucky, setLucky] = useState(false);

    // //location 1
    // const loc1 = {posX: 368, posY: 785, radius: 164};
    //
    // //location 2
    // const loc2 = {posX: 381, posY: 658, radius: 150};

    const constructVid = (loop) => {
        let vid = document.createElement('video');
        let source1 = document.createElement('source');
        let file = state.isWhite ? 'EP2-white' : 'EP2';
        let sr1 = "https://elements.cocafe.co/nfo/animations/ep-2/" + file + ".webm";
        source1.setAttribute("src",sr1);
        source1.setAttribute("type","video/webm");
        vid.appendChild(source1);
        let source2 = document.createElement('source');
        let sr2 = "https://elements.cocafe.co/nfo/animations/ep-2/" + file + ".mp4";
        source2.setAttribute("src",sr2);
        source2.setAttribute("type","video/mp4");
        vid.appendChild(source2);
        vid.muted = true;
        vid.loop = loop;
        vid.autoplay = true;
        vid.crossOrigin = "anonymous";
        vid.playsInline = true;
        vid.preload = "auto";
        vid.addEventListener('loadedmetadata', (e) => {
            if(vid.readyState >= 1){
                setTimeout(() => {setLoaded(true);},2500)
            }
        });

        if(window.navigator.userAgent.indexOf("iPhone") > -1){
            setTimeout(() => {
               setLoaded(true);
            },4000)
        }
        return vid;
    }

    const distanceBetween = (x1, y1, x2, y2) => {
       return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2);
    }

    const [clicked] = useState(() => {
       return constructVid(false);
    })


    const handleStart = (e) => {
        if(clicked.currentTime === 0 && clicked.paused && loaded) {
            setTimeout(() => {setEntered(true)},500);
            // setEntered(true);
            clicked.play();
            animClicked.current = new Konva.Animation(() => {
                videoMid.current.image(clicked);
            },videoMid.current.getLayer());
            animClicked.current.start();
            setTimeout(() => {setLucky(true)},9500);
        }

        // else if(loaded && distanceBetween(x,y,loc1.posX, loc1.posY) < loc1.radius * loc1.radius && !oreoFound){
        //     //oreo found
        //
        // }
        // else if(loaded && oreoFound && distanceBetween(x,y,loc2.posX, loc2.posY) < loc2.radius * loc2.radius ){
        //     //oreo twisted
        //     dispatch({
        //         type: "LOGINMODAL",
        //         payload:{
        //             isModalOpen: true
        //         }
        //     })
        //     // console.log(oreoTwisted);
        //     // setLucky(true);
        // }
    }
    const goCongrats = () => {
        try {   
            state.mai.memberEvent.page.logOperate({
                type: "click",
                content: "NFT海报生成-页面-页面浏览-点击赶快戳按钮",
                pageContent: 'NFT海报生成-页面-页面浏览',
                utmCampaign: "nft",
            });
        } catch (error) {
            console.log(error);
        }
        if(entered && lucky){
            window.location = "/congrats";
        }

    }

    return(
        <div onTouchStart={handleStart}
             onClick={handleStart}
        >
            {!entered && <VerticalEnter loaded={loaded}/>}
            <Stage width={window.innerWidth} height={1624 * scale} style={{position: "absolute", top: 0, left:0}}>
                <Layer>
                    <Image ref={videoMid} x={0} y={0} width={window.innerWidth} height={1624 * scale}/>
                </Layer>
            </Stage>
            <div id="congrats-container" style={{opacity : lucky ? '1' : '0'}}>
                <div style={{position:'absolute', top: '132px'}}>
                    想要专属于你的<br/>
                    那块奥利奥数字饼干吗?
                </div>
                <div onClick={goCongrats} id="retrieve-button">赶快戳</div>
            </div>
        </div>
    );
}

