import "./enter.css";
import loading from "../assets/loading-2.png"
import loadingV from "../assets/loading-2vertical.png";
import stamper from "../assets/stamper.png";
import stamperGrey from "../assets/stamper-grey.png"
import point from "../assets/point.png";
import {useEffect, useState} from "react";
import {Box, LinearProgress, linearProgressClasses} from "@mui/material"
import styled from "@emotion/styled";
export default function Enter({loaded}){

    const [width] = useState(window.innerWidth);
    const [height] = useState(window.innerHeight)

    const handleRotate = () => {
        window.location.reload();
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        // border: '0.8px solid rgba(196, 196, 196, 0.3)',
        height: 8,
        margin:4,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'rgba(196, 196, 196, 0.4)',
        },
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#848484',
        },
    }));

    useEffect(() => {
        window.addEventListener('orientationchange', handleRotate);
        return () => {
            window.removeEventListener('orientationchange',handleRotate)}
    },[])

    return(
        <div id="enter-container" style={{width, height}}>
            <img src={loading} width={width} id="loading-hor"/>
            <img src={loadingV} width={width} id="loading-vertical"/>
            <img src={stamperGrey} id="stamper-grey"/>
            <img src={stamper} id="stamper-red" style={{opacity: loaded ? 1 : 0, transition: "opacity 1s ease 0.1s"}}/>
            <div id="point-container" style={{display: loaded ? 'block' : 'none'}}>
                <img src={point}/>
            </div>
            <Box id="loading-container" style={{display: loaded ? 'none' : 'block'}} sx={{ width: '156px',zIndex:5,height: "18px", position: "absolute",border: '0.8px solid rgba(196, 196, 196, 0.3)'}}>
                <BorderLinearProgress />
            </Box>
        </div>
    );
}