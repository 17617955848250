import { Box, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { serverAddress, AuthContext } from "../App";
import { useContext } from "react";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import headerbg from "../assets/login-header.png";
import rectangle547 from "../assets/Rectangle547.png";
import footerbg from "../assets/footer-background.png";

import "./Login.css";

export default function Login({ isOpen }) {
  const { state, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(isOpen);
  const handleClose = () => {
    dispatch({
      type: "LOGINMODAL",
      payload: { isModalOpen: false },
    });
  };
  const [phone, setPhone] = useState();
  const [code, setCode] = useState();
  const phoneOnchanged = (e) => {
    setPhoneValidate(false);
    setPhone(e.target.value);
  };
  const codeOnchanged = (e) => {
    setCodeValidate(false);
    setCode(e.target.value);
  };
  const [phoneValidate, setPhoneValidate] = useState(false);
  const [codeValidate, setCodeValidate] = useState(false);
  const getSMS = () => {
    if (/^1\d{10}$/.test(phone)) {
      if (!btnBool) {
        setBtnBool(true);
        setBtnText(60);
      }
      let headers = new Headers();
      headers.append("accountId", state.accountId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/user/sms", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ phone: phone }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.returnCode !== "20000") {
            setPhoneValidate(true);
          }
        });
    } else {
      setPhoneValidate(true);
    }
  };

  const [btnBool, setBtnBool] = useState(false);
  const [btnText, setBtnText] = useState("获取");
  useEffect(() => {
    if (btnText !== "获取" && btnText !== 1) {
      setTimeout(() => {
        setBtnText(btnText - 1);
      }, 1000);
    }
    if (btnText === 1) {
      setTimeout(() => {
        setBtnText("获取");
        setBtnBool(false);
      }, 1000);
    }
  }, [btnText]);
  const handleCancel = () => {
    dispatch({
      type: "LOGINMODAL",
      payload: { isModalOpen: false },
    });
  };
  const handleConfirm = () => {
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击下一步",
        pageContent: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
    if (/^1\d{10}$/.test(phone)) {
      let headers = new Headers();
      headers.append("accountId", state.accountId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/user/register", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ phone: phone, code: code }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.returnCode === "20000") {
            fetch(serverAddress + "/tokenid", {
              method: "GET",
              headers: headers,
            })
              .then((res) => {
                if (res.status === 200) {
                  res.json().then((res) => {
                    if (res.returnCode === "20000") {
                      dispatch({
                        type: "REGISTER",
                        payload: {
                          hasWithdrawn: true,
                          tokenId: res.data.tokenId,
                        },
                      });
                      dispatch({
                        type: "LOGINMODAL",
                        payload: {
                          isModalOpen: false,
                        },
                      });
                    } else {
                      return null;
                    }
                  });
                } else {
                  return null;
                }
              })
              .catch((e) => {
                return null;
              });
          } else {
            setCodeValidate(true);
          }
        });
    } else {
      setPhoneValidate(true);
    }
  };
  useEffect(() => {
    setOpen(state.isModalOpen);
  }, [state.isModalOpen]);

  const [ruleChecked, setRuleChecked] = useState(false);
  const checkboxOnchanged = (event) => {
    setRuleChecked(event.target.checked);
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {},
      }}
      onClose={handleClose}
      open={open}
    >
      <div className="relative overflow-hidden" style={{ color: "#474747" }}>
        <img className="absolute z-10 w-full" src={headerbg}></img>
        <img className="absolute z-0 w-full" src={footerbg}></img>
        <div className="px-8 pt-8 relative z-20">
          <div
            className="text"
            style={{
              color: "#A6907B",
              textShadow: "0px 2px 1px rgba(185, 161, 138, 0.2)",
            }}
          >
            您离收入囊中仅一步之遥
          </div>
          <div>
            <div className="text-xs mr-2 my-4 text-justify">
              请输入手机号和验证码，完成coPortal的注册/登录，coPortal将帮助您安全地保管数字资产，完成“扭一扭”。
            </div>
            <div className="flex">
              <div className="relative text-xs top-1">手机号</div>
              <div className="ml-3">
                <input
                  type="text"
                  onChange={phoneOnchanged}
                  style={{
                    width: "10.25rem",
                    height: "1.625rem",
                    padding: "7px",
                    fontSize: "0.75rem",
                    boxSizing: "border-box",
                    border: "0.6px solid #474747",
                    boxSizing: "border-box",
                    borderRadius: "2px",
                    backgroundColor: "transparent",
                  }}
                ></input>
                {phoneValidate ? (
                  <div
                    className="flex justify-left"
                    style={{
                      height: "1.125rem",
                    }}
                  >
                    <div
                      style={{
                        color: "#EF1515",
                        fontSize: "12px",
                        transform: "scale(0.83, 0.83)",
                        position: "relative",
                        left: "-10px",
                      }}
                    >
                      请输入正确的手机号
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "1.125rem",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="text-xs relative top-1">验证码</div>
              <div className="ml-3">
                <input
                  onChange={codeOnchanged}
                  style={{
                    width: "4.75rem",
                    height: "1.625rem",
                    padding: "7px",
                    fontSize: "0.75rem",
                    border: "0.6px solid #474747",
                    boxSizing: "border-box",
                    borderRadius: "2px",
                    backgroundColor: "transparent",
                  }}
                ></input>
                {codeValidate ? (
                  <div
                    className="flex justify-left"
                    style={{
                      height: "1.125rem",
                    }}
                  >
                    <div
                      style={{
                        color: "#EF1515",
                        fontSize: "12px",
                        transform: "scale(0.83, 0.83)",
                        position: "relative",
                        left: "-6px",
                      }}
                    >
                      验证码不正确
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "1.125rem",
                    }}
                  ></div>
                )}
              </div>
              <button
                onClick={getSMS}
                disabled={btnBool}
                className="ml-3 text-sm login-button"
                style={{ position: "relative", top: "-1.5px" }}
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
        <div className="relative z-20" style={{ margin: "1.125rem" }}>
          <div
            className="text-xs"
            style={{
              color: "#A6907B",
              margin: "0.875rem 0.875rem 0",
              textShadow: "0px 2px 1px rgba(185, 161, 138, 0.2)",
            }}
          >
            请仔细阅读本协议至末尾，勾选同意以进行下一步。
          </div>
          <div
            style={{
              padding: "0.875rem",
              backdropFilter: "blur(19px)",
              borderRadius: "8px",
              backgroundImage: "url(" + rectangle547 + ")",
              backgroundSize: "cover",
            }}
          >
            <div
              className="text-xs declaration-scroll"
              style={{ height: "10rem" }}
            >
              <div>
                <div className="font-bold">
                  cocafe咖菲科技用户使用协议和免责声明
                </div>
                <div className="declaration">
                  <p>
                    您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，且知晓并理解本协议所有条款的含义及相应的法律后果，本协议即视为已于您在线点击确认或以其他方式签署本协议之日有效签署，对各方成立并生效。
                  </p>
                  <p>
                    1.
                    本站点为cocafe咖菲科技所有并进行维护。下文中的“我们”指cocafe咖菲科技；“亿滋”指亿滋食品企业管理（上海)
                    有限公司。
                  </p>
                  <p>
                    2. 本次活动数字藏品 (即NFO数字奥利奥饼干)
                    的领取对象为年龄在18周岁以上的中国公民。如您在本站点领取数字藏品，即表示您已经知晓并符合了该领取标准。若您因未符合年龄标准而导致发生的任何违法法律法规行为，需要您自行承担法律责任，同时本站点保留取消您领取资格的权利。
                  </p>
                  <p>
                    3.
                    数字藏品的拥有者不得将数字藏品用于任何商业用途。您获得的数字藏品为非卖品，无市场价格。NFO数字饼干仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖，或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途。本站点仅为展示数字藏品，严格禁止将数字藏品进行炒作、场外交易、欺诈等任何非法用途。请远离数字藏品非理性炒作，防范欺诈风险。
                  </p>
                  <p>
                    4.
                    您需要验证并授权绑定手机号领取以及提取您的数字藏品或使用本站点服务。在您登陆本站点时，您同意我们获取您的手机号；如果您不授予前述同意，您将无法领取或提取数字藏品，并可能无法正常使用本站点的服务。我们将严格遵守相关法律法规与监管要求，依法使用您的信息，并对您的信息保密。
                  </p>
                  <p>
                    5. 在您于本站点领取数字藏品后 至
                    您提取该数字藏品至自己个人区块链钱包之前（
                    具体提取时间段为：2022/02/14 00:00- 2022/12/31 23:59:59 )
                    ，手机号是您在本站点绑定账号以及在奥利奥微信小程序查看NFO数字藏品的唯一凭证。
                    一个奥利奥小程序ID对应一个手机号（id)
                    ，并对应唯一一个NFO数字藏品，
                    请务必妥善保管好您的手机号。如发现有任何恶意利用多个手机号或者其他不正当行为获取NFO数字藏品的行为，cocafe有权取消该用户的领取资格和NFO数字藏品。
                  </p>
                  <p>
                    6.
                    当您将数字藏品从本cocafe站点提取到个人的区块链钱包后，您需妥善保管自己的数字藏品和自己的区块链钱包地址。提取完成后发生的一切遗失、失窃、无法找回等情形，均由您自行承担。如有使用或提取数字藏品疑问的，您可以通过
                    nft@cocafe.co 咨询cocafe咖菲科技。
                    <br />
                    *关于钱包创建和提取方法，请以conflux网站
                    <a href="https://portal.confluxnetwork.org/">
                      （https://portal.confluxnetwork.org/)
                    </a>
                    说明及本站点后续的公告为准。
                    <br />
                    cocafe咖菲科技未来在本站点的服务功能会升级和迭代，具体以cocafe咖菲科技公布为准。
                  </p>
                  <p>
                    7.
                    基于区块链技术的特性，数字藏品具有唯一、不可篡改的特点，数字藏品中的数字内容，铸造时间等信息无法篡改。在您获得数字藏品后，您与数字藏品的所有权等权利关系将通过智能合约记录在区块数据中。
                  </p>
                  <p>
                    8.
                    您理解并同意，您领取NFO数字藏品后，只拥有该数字藏品本身的物权，不拥有该数字藏品对应的原艺术品的所有权及包括著作权在内的各项知识产权，亦不拥有该数字藏品的知识产权。
                  </p>
                  <p>
                    9.
                    您理解并同意，基于维护网络秩序和安全，如我们发现您存在违法违规或违反本协议约定情形的，我们有权采取同时采取一项或多项措施，包括但不限于：
                    A) 要求您限期纠正违规或违约行为； B)
                    屏蔽或限制您使用数字藏品； C) 对违法违规信息进行删除或屏蔽
                    D) 停或终止向您提供部分或全部服务； E)
                    其他合理、合法的措施。
                    我们因任何原因未采取上述任何措施，不代表我们放弃未来采取该等措施的权利。如您的行为使我们或亿滋遭受损失的，我们还有权要求您赔偿我们或亿滋的全部损失，包括商誉损失、赔偿金、律师费、诉讼费等。
                  </p>
                  <p>
                    10.
                    您理解并同意，在您获得数字藏品和使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议约定的，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任：
                    A) 在与本服务相关的系统停机维护或升级期间； B)
                    因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因；
                    C) 您操作不当或通过非我们授权或认可的方式使用本服务； D)
                    因病毒、木马、恶意程序攻击网络拥堵、系统不稳定、系统或设备故障、通讯故障电力故障、电信设备故障、第三方服务瑕疵等原因。
                  </p>
                  <p>
                    11.
                    您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的责任。
                  </p>
                  <p>
                    12.
                    除上文已经特别指出的以外，发行站点上的所有内容，包括但不限于文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由发行方/创作者/合作方/依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经我们书面同意，您不得擅自使用、修改、反向编译，复制，公开传播、改变、散布、发行或公开发表、转让、许可、商业利用本站点的相关软件、内容以及我们发行方和合作方享有的知识产权。否则我们有权立即终止本协议，您应对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。
                  </p>
                  <p>
                    13.
                    为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您应立即停止使用相关服务。若您继续使用本协议相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束。
                  </p>
                </div>
              </div>
              <FormGroup>
                <FormControlLabel
                  className="flex justify-center"
                  control={
                    <Checkbox
                      size="small"
                      style={{ transform: "scale(0.8)" }}
                      sx={{
                        "& .PrivateSwitchBase-root": {
                          width: "0.75rem",
                          height: "0.75rem",
                        },
                        "& .MuiTypography": {
                          fontSize: "12px",
                        },
                      }}
                      onChange={checkboxOnchanged}
                    />
                  }
                  label={
                    <Box component="div" fontSize={12}>
                      我已阅读并同意用户协议
                    </Box>
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-8 relative z-20">
          <button className="login-button" id="cancel" onClick={handleCancel}>
            取消
          </button>
          <button
            className="ml-10 login-button"
            onClick={handleConfirm}
            disabled={!ruleChecked}
          >
            下一步
          </button>
        </div>
      </div>
    </Dialog>
  );
}
