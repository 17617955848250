import enter from "../assets/enter.jpg";
import white from "../assets/white-loading.png";
import "./verticalenter.css";
import {useEffect, useState} from "react";
import {Box, LinearProgress, linearProgressClasses} from "@mui/material"
import styled from "@emotion/styled";
import LoadingText from "./loadingText";
export default function VerticalEnter({loaded}){
    const [width] = useState(window.innerWidth);
    const [height] = useState(window.innerHeight);

    // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    //     // border: '0.8px solid rgba(196, 196, 196, 0.3)',
    //     height: 8,
    //     margin:4,
    //     [`&.${linearProgressClasses.colorPrimary}`]: {
    //         backgroundColor: 'rgba(196, 196, 196, 0.4)',
    //     },
    //     [`& .${linearProgressClasses.bar}`]: {
    //         backgroundColor: '#848484',
    //     },
    // }));
    return(
        <div id="vertical-enter" style={{width, height}}>
            <img src={enter} width={width}/>
            <div id="vertical-loading">
                <img src={white} width={122}/>
                <LoadingText loaded={loaded} needsPointer={true}/>
            </div>
        </div>
    );
}