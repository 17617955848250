import React, {useEffect, useState} from "react";
import point from "../assets/pointer.png"

export default function LoadingText({loaded,needsPointer}){
    const [loadingDot, setDot] = useState('');

    useEffect(() => {
        let timer = setTimeout(() => {
            if (loadingDot.length < 3) {
                let newDot = loadingDot + '.';
                setDot(newDot);
            } else if (loadingDot.length === 3) {
                setDot('');
            }
        }, 500);
        // return clearTimeout(timer);
    }, [loadingDot]);

    return(
        <div className="loading-content">
            <div className="mt-5 text-sm" style={{opacity:loaded ? '0' : '1', transition: "opacity 0.2s ease 0.1s"}}>努力加载中{loadingDot}</div>

            {loaded && needsPointer && 
            <div style={{width:"40px", animation: "blink 1.5s linear infinite", position:'absolute', top:'170px'}} >
                <img src={point}/>
            </div>}

        </div>

    );
}