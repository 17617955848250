import React, { useContext, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage, Text } from "react-konva";
import loadingOreo from "../assets/loading-oreo.png";
import loadingBack from "../assets/loading-background.png";
import tagline1 from "../assets/tagline1.png";
import LoadingText from "./loadingText";
import "./poster.css";
import { AuthContext, serverAddress } from "../App";
import point from "../assets/gold-hand.png"
import { OSS } from "ali-oss";

export default function PosterTest() {

  const ratio = window.devicePixelRatio;


  const [deviceWidth] = useState(window.innerWidth);
  const [height] = useState(window.innerHeight);
  const { state, dispatch } = useContext(AuthContext);
  const stageRef = useRef();
  const output = useRef();
  const [oreoNfo, setNfo] = useState();
  const [qrCode, setQrCode] = useState();
  const [tagline, setTagline] = useState();
  const [loaded, setLoaded] = useState(false);
  const [loadingDot, setDot] = useState("");
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [oreoLoaded, setOreoLoaded] = useState(false);
  const [pressed, setPressed] = useState(false);

  const [client, setOssClient] = useState(null);

  const imgWidth = 540;
  const scale = deviceWidth / imgWidth;

  const uiWith = 540;
  const uiHeight = 1170;

  const [contract, setContract] = useState();
  const [tokenId, setTokenId] = useState();
  const [templateImg, setTemplate] = useState();
  const [nickname, setNickname] = useState();
  const [owner, setOwner] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [poster, setPoster] = useState();

  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 19, 19);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };



  useEffect(() => {
    let taglineImg = document.createElement("img");
    taglineImg.crossOrigin = "anonymous";
    taglineImg.src = tagline1;
    setTagline(taglineImg);
    try {
      state.mai.memberEvent.page.logView({
        content: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("accountId", state.accountId);
      headers.append("accessToken", state.accessToken);

      fetch(serverAddress + "/ststoken",{
        method: "GET",
        headers: headers,
      }).then((res) => {
        if(res.status === 200){
          res.json().then((res) => {
            if(res.returnCode === "20000"){
              const {accessKeyID,accessKeySecret,securityToken} = res.data;
              const client = new window.OSS.Wrapper({
                region:'oss-cn-shanghai',
                accessKeyId: accessKeyID,
                accessKeySecret,
                stsToken: securityToken,
                bucket: 'bucket-elements'
              })
              setOssClient(client);
            }
          })
        }
      });
      fetch(serverAddress + "/posters/e2", {
        method: "GET",
        headers: headers,
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              let contract = getSubStr(res.data.contract);
              setContract(contract);
              setTokenId(res.data.tokenId);
              let url =
                "https://elements.cocafe.co/nfo/image/static/" +
                res.data.tokenId +
                ".png";
              let nfoImg = document.createElement("img");
              nfoImg.crossOrigin = "anonymous";
              nfoImg.src = url;
              nfoImg.onload = () => {
                setOreoLoaded(true);
              };
              setNfo(nfoImg);
              let template =
                "https://bucket-elements.oss-cn-shanghai.aliyuncs.com/nfo/image/posters/" +
                res.data.templateId +
                ".png";
              // let template =
              //   "https://bucket-elements.oss-cn-shanghai.aliyuncs.com/nfo/image/posters/8.png";
              let tempImg = document.createElement("img");
              tempImg.crossOrigin = "anonymous";
              tempImg.src = template;
              tempImg.onload = () => {
                setTemplateLoaded(true);
              };
              setTemplate(tempImg);
              let owner = getSubStr(res.data.owner);
              setOwner(owner);
              setNickname(res.data.nickname);
              setUpdateTime(res.data.updatetime);
              let qrImg = document.createElement("img");
              qrImg.crossOrigin = "anonymous";
              qrImg.src = res.data.qrCode;
              setQrCode(qrImg);
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
      });
    }
  }, [state.isLogin]);

  useEffect(() => {
    if (oreoLoaded && templateLoaded && client !== null) {
      setTimeout(() => {
        // try {
          const canvas = stageRef.current.toCanvas({pixelRatio: ratio});
          let dir = state.sync ? 'sync/' : 'temp/'
          let path = "nfo/metaverse/" + dir + state.tokenId + '.jpg';
          client.list({prefix:path}).then((res) => {
            if(res.objects && res.objects.length > 0){
              let url = "https://elements.cocafe.co/" + path;
              let result = document.createElement("img");
              result.crossOrigin = "anonymous";
              result.src = url;
              output.current.appendChild(result);
              output.current.scrollTo(0,0);
              result.onload = () => {
                setLoaded(true);
              }
            }
            else {
              canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = function (event) {
                  // 文件名
                  // const objectKey = `posters/` + state.tokenId + '.jpg';
                  // arrayBuffer转Buffer
                  const buffer = new window.OSS.Buffer(event.target.result);
                  // 上传
                  client.put(path, buffer).then((res) => {
                    let url = "https://elements.cocafe.co/" + path;
                    let result = document.createElement("img");
                    result.crossOrigin = "anonymous";
                    result.src = url;
                    output.current.appendChild(result);
                    output.current.scrollTo(0,0);
                    result.onload = () => {
                      setLoaded(true);
                    }
                  }).catch(function(err){
                    console.log(err);
                  });
                }


                // let result = document.createElement("img");
                // let url = URL.createObjectURL(blob);
                // result.crossOrigin = "anonymous";
                // result.src = url;
                // result.width = deviceWidth;
                // result.height = deviceWidth * (uiHeight / uiWith);
                // output.current.appendChild(result);
                // output.current.scrollTo(0,0);
                // setLoaded(true);
              },'image/jpeg',1);
            }
          });



        },500);
        // catch (e) {
        //   console.log(e);
        //   // alert(e);
        //   // alert(JSON.stringify(e));
        // }
    }
  }, [oreoLoaded, templateLoaded,client]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (loadingDot.length < 3) {
        let newDot = loadingDot + ".";
        setDot(newDot);
      } else if (loadingDot.length === 3) {
        setDot("");
      }
    }, 500);
    return () => {clearTimeout(timer);}
  }, [loadingDot]);

  const handleTap = () => {
      if(loaded) setPressed(true);
  }

  return (
    <div>
      {!pressed &&(
        <div
          id="poster-enter"
          style={{ deviceWidth, zIndex: 3, backgroundColor: "white" }}
          onClick={handleTap}
          onTouchStart={handleTap}
        >
          <img src={loadingBack} width={deviceWidth} />
          <div id="poster-loading">
            <img src={loadingOreo} width={122} />
            <div className="mt-5 text-sm">
              {
                loaded ? "长按保存海报，分享到朋友圈秀一秀吧！" : `努力加载中${loadingDot}`
              }
            </div>

              {loaded &&
              <div style={{width:"60px", animation: "blink 1.5s linear infinite", position:'absolute', top:'170px',zIndex:3}} >
                <img src={point}/>
              </div>}
          </div>
        </div>
      )}
      <div style={{ position: "absolute", top: 0, left: 0, zIndex: 1,display: 'flex',alignItems:'center',justifyContent: 'center' }} ref={output}>
      </div>

      {!loaded &&
      <Stage
          className="absolute left-0 top-0"
          width={deviceWidth}
          height={deviceWidth * (uiHeight / uiWith)}
          ref={stageRef}
      >
        <Layer>
          <Image
              image={oreoNfo}
              x={(249 / uiWith) * deviceWidth}
              y={(316 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              width={233 * scale}
              height={233 * scale}
          />
          <Image image={templateImg} scale={{ x: scale, y: scale }} />
          <Image
              image={qrCode}
              x={(416 / uiWith) * deviceWidth}
              y={(1000 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              width={90 * scale}
              height={90 * scale}
          />
          <Text
              text="「数字藏品为非卖品。不可转让不可售卖，仅供观赏和收藏」"
              x={(54 / uiWith) * deviceWidth}
              y={(48 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              scale={{ x: scale, y: scale }}
              fontFamily="FZZhengHeiS"
              fontSize={16}
              lineHeight={2}
              fill="rgba(94, 94, 94, 0.75)"
          />
          <Image
              image={tagline}
              x={(87 / uiWith) * deviceWidth}
              y={(198 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              width={365 * scale}
              height={121.5 * scale}
          />
          <Text
              text={"NFO #" + tokenId}
              x={(399 / uiWith) * deviceWidth}
              y={(734 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              scale={{ x: scale, y: scale }}
              fontFamily="Glass TTY VT220"
              fontSize={28}
              fill={state.isWhite ? "#514231" : "#3A3A3A"}
          />
          {state.isWhite ? (
              <Text
                  text="智能合约"
                  x={(26 / uiWith) * deviceWidth}
                  y={(794 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                  fillLinearGradientEndPoint={{ x: 0, y: 22 }}
                  fillLinearGradientColorStops={[
                    0,
                    "rgba(192, 176, 145, 1)",
                    0.4271,
                    "rgba(108, 84, 57, 1)",
                  ]}
              />
          ) : (
              <Text
                  text="智能合约"
                  x={(26 / uiWith) * deviceWidth}
                  y={(794 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fill="#6F7781"
              />
          )}
          <Text
              text={contract}
              x={(26 / uiWith) * deviceWidth}
              y={(822 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              scale={{ x: scale, y: scale }}
              fontFamily="FZZhengHeiS"
              fontSize={18}
              fill={state.isWhite ? "#514231" : "#363A3F"}
          />
          {state.isWhite ? (
              <Text
                  text="收藏者"
                  x={(26 / uiWith) * deviceWidth}
                  y={(864 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                  fillLinearGradientEndPoint={{ x: 0, y: 22 }}
                  fillLinearGradientColorStops={[
                    0,
                    "rgba(192, 176, 145, 1)",
                    0.4271,
                    "rgba(108, 84, 57, 1)",
                  ]}
              />
          ) : (
              <Text
                  text="收藏者"
                  x={(26 / uiWith) * deviceWidth}
                  y={(864 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fill="#6F7781"
              />
          )}
          <Text
              text={nickname}
              x={(26 / uiWith) * deviceWidth}
              y={(892 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              scale={{ x: scale, y: scale }}
              fontFamily="FZZhengHeiS"
              fontSize={18}
              fill={state.isWhite ? "#514231" : "#363A3F"}
          />
          {state.isWhite ? (
              <Text
                  text="我的专属区块链身份凭证"
                  x={(26 / uiWith) * deviceWidth}
                  y={(933 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                  fillLinearGradientEndPoint={{ x: 0, y: 22 }}
                  fillLinearGradientColorStops={[
                    0,
                    "rgba(192, 176, 145, 1)",
                    0.4271,
                    "rgba(108, 84, 57, 1)",
                  ]}
              />
          ) : (
              <Text
                  text="我的专属区块链身份凭证"
                  x={(26 / uiWith) * deviceWidth}
                  y={(933 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fill="#6F7781"
              />
          )}
          <Text
              text={owner}
              x={(26 / uiWith) * deviceWidth}
              y={(961 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              scale={{ x: scale, y: scale }}
              fontFamily="FZZhengHeiS"
              fontSize={18}
              fill={state.isWhite ? "#514231" : "#363A3F"}
          />
          {state.isWhite ? (
              <Text
                  text="认证时间"
                  x={(26 / uiWith) * deviceWidth}
                  y={(1002 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                  fillLinearGradientEndPoint={{ x: 0, y: 22 }}
                  fillLinearGradientColorStops={[
                    0,
                    "rgba(192, 176, 145, 1)",
                    0.4271,
                    "rgba(108, 84, 57, 1)",
                  ]}
              />
          ) : (
              <Text
                  text="认证时间"
                  x={(26 / uiWith) * deviceWidth}
                  y={(1002 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
                  scale={{ x: scale, y: scale }}
                  fontFamily="FZZhengHeiS"
                  fontSize={22}
                  fill="#6F7781"
              />
          )}
          <Text
              text={updateTime}
              x={(26 / uiWith) * deviceWidth}
              y={(1030 / uiHeight) * deviceWidth * (uiHeight / uiWith)}
              scale={{ x: scale, y: scale }}
              fontFamily="FZZhengHeiS"
              fontSize={18}
              fill={state.isWhite ? "#514231" : "#363A3F"}
          />
        </Layer>
      </Stage>
      }

    </div>
  );
}
