import Login from "../../components/Login";
import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../App";
import blackOreoFull from "../../assets/black oreo full.png";
import whiteOreoFull from "../../assets/white oreo full.png";
import congratsBackground from "../../assets/congrats-background.png";

export default function Congrats() {
  const { state, dispatch } = useContext(AuthContext);
  document.title = "玩心奥利奥元宇宙";

  const handleModal = () => {
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击去收入囊中",
        pageContent: 'NFT海报生成-页面-页面浏览',
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: "LOGINMODAL",
      payload: {
        isModalOpen: true,
      },
    });
  };

  const imgRef = useRef(null);

  useEffect(() => {
    if (state.isWhite) {
      imgRef.current.src = whiteOreoFull;
    } else if (state.isWhite !== null && !state.isWhite) {
      imgRef.current.src = blackOreoFull;
    }
  }, [state.isWhite]);

  useEffect(() => {
    if (state.hasWithdrawn && state.tokenId) {
      window.location = "/nfo/" + state.tokenId;
    }
  }, [state.hasWithdrawn, state.tokenId]);
  return (
    <div>
      {state.isModalOpen && <Login isOpen={state.isModalOpen} />}
      <img
        className="absolute z-0 w-full"
        src={congratsBackground}
        style={{ height: window.innerHeight }}
      />
      <div
        className="relative flex flex-col items-center"
        style={{
          top: `${(507 / 1624) * 100}vh`,
        }}
      >
        <div
          className="relative"
          style={{
            width: `${(561 / 750) * 100}vw`,
          }}
        >
          <img
            className="relative z-10"
            ref={imgRef}
            style={{
              width: `${(561 / 750) * 100}vw`,
            }}
          />
          <img
            className="absolute z-0"
            src={
              state.tokenId &&
              "https://elements.cocafe.co/nfo/image/small-gif/" +
                state.tokenId +
                ".gif"
            }
            style={{
              left: `${(254 / 750) * 100}vw`,
              top: `${(18 / 750) * 100}vw`,
              width: `${(290 / 750) * 100}vw`,
            }}
          />
        </div>
        <div className="relative" style={{ top: `1.3725rem` }}>
          <div
            className="text-lg"
            style={{
              color: "#A6907B",
              textShadow: "0px 2px 1px rgba(185, 161, 138, 0.2)",
            }}
          >
            恭喜成为“天选之子”， <br />
            这块独一无二的数字饼干只专属于你！
          </div>
          <div
            className="relative flex justify-center"
            style={{
              top: `8vh`,
            }}
            onClick={handleModal}
          >
            <button
              className="text-sm"
              style={{
                width: "104px",
                height: "29px",
                color: "#ffffff",
                background:
                  "linear-gradient(95.71deg, #AA8964 0.47%, #E7D7BC 99.37%)",
                boxShadow: "0px 1px 1px rgba(172, 139, 103, 0.4)",
                borderRadius: "4px",
              }}
            >
              去收入囊中
            </button>
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-4 text-xs text-center w-full"
        style={{ color: "#6D6D6D" }}
      >
        「数字藏品为非卖品。不可转让不可售卖，仅供观赏和收藏」
      </div>
    </div>
  );
}
