import React, { useContext, useEffect, useRef, useState } from "react";
import {toJpeg, toPng} from "html-to-image";
import { serverAddress, AuthContext } from "../../App";
// import poster from "../../assets/poster-mask.png";
import PosterTest from "../../components/PosterTest";
import loadingOreo from "../../assets/loading-oreo.png";
import "./index.css";
import {data} from "autoprefixer";

export default function Poster() {
  const { state, dispatch } = useContext(AuthContext);
  const [contract, setContract] = useState();
  const [tokenId, setTokenId] = useState();
  const [nfoUrl, setNfoUrl] = useState();
  const [templateId, setTemplateId] = useState();
  const [owner, setOwner] = useState();
  const [qrCode, setQrCode] = useState();
  // useEffect(() => {
  //   if (state.isLogin) {
  //     let headers = new Headers();
  //     headers.append("accountId", state.accountId);
  //     headers.append("accessToken", state.accessToken);
  //     fetch(serverAddress + "/posters/e2", {
  //       method: "GET",
  //       headers: headers,
  //     }).then((res) => {
  //       if (res.status === 200) {
  //         res.json().then((res) => {
  //           if (res.returnCode === "20000") {
  //             setContract(res.data.contract);
  //             setTokenId(res.data.tokenId);
  //             setNfoUrl(
  //               "https://elements.cocafe.co/nfo/image/static/" +
  //                 res.data.tokenId +
  //                 ".png"
  //             );
  //             setTemplateId(res.data.templateId);
  //             setOwner(res.data.owner);
  //             setQrCode(res.data.qrCode);
  //           } else {
  //             return null;
  //           }
  //         });
  //       } else {
  //         return null;
  //       }
  //     });
  //   }
  // }, [state.isLogin]);
  // const ref = useRef(null);
  // const [count, setCount] = useState(0);
  // const load = () => {
  //   setCount(count + 1);
  //   let node = document.getElementById("html2canvas-conetent");
  //   toJpeg(node, { cacheBust: true })
  //     .then((dataUrl) => {
  //       var img = new Image();
  //       img.src = dataUrl;
  //       // document.getElementById("poster").appendChild(img);
  //       // var el = document.getElementById("html2canvas-conetent");
  //       var el1 = document.getElementById("loading-cover");
  //       // el.remove();
  //       el1.remove();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const [isQrcodeLoaded, setIsQrcodeLoaded] = useState(false);
  // const [isNfoLoaded, setIsNfoLoaded] = useState(false);
  // const [isBgLoaded, setIsBgLoaded] = useState(false);
  // const qrcodeLoaded = () => {
  //   setIsQrcodeLoaded(true);
  // };
  // const nfoLoaded = () => {
  //   setIsNfoLoaded(true);
  // };
  // const bgLoaded = () => {
  //   setIsBgLoaded(true);
  // };
  // useEffect(() => {
  //   if (isQrcodeLoaded && isNfoLoaded && isBgLoaded) {
  //     // setTimeout(load,10000)
  //     load();
  //     // load();
  //   }
  // }, [isQrcodeLoaded, isNfoLoaded, isBgLoaded]);
  // const [dotCount, setDotCount] = useState(0);
  // const [loadingDot, setLoadingDot] = useState(".");
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (dotCount < 3) {
  //       setDotCount(dotCount + 1);
  //     } else if (dotCount === 3) {
  //       setDotCount(0);
  //     }
  //   }, 800);
  // }, [dotCount]);
  // useEffect(() => {
  //   if (dotCount === 0) {
  //     setLoadingDot("");
  //   } else {
  //     for (let index = 0; index < dotCount; index++) {
  //       setLoadingDot(loadingDot + ".");
  //     }
  //   }
  // }, [dotCount]);
  return (
      <PosterTest />
    // <div id="canvas-content">
    //
    //   <div id="poster" style={{position:'absolute', left: '0', top:'0',zIndex: 3}}>
    //
    //   </div>
    //   <div
    //     id="loading-cover"
    //     className="loading-page flex justify-center items-center absolute z-10"
    //     style={{ width: "100vw", height: "100vh", backgroundColor: "white" }}
    //   >
    //     <div>
    //       <img src={loadingOreo}></img>
    //       <div className="mt-5 text-sm">努力加载中{loadingDot}</div>
    //     </div>
    //   </div>
    //   <div id="html2canvas-conetent" className="relative z-0" ref={ref}>
    //     <div className="relative">
    //       <img  onLoad={bgLoaded} className="relative" src={poster} />
    //       <img onLoad={nfoLoaded}
    //         className="absolute"
    //         src={nfoUrl}
    //         style={{
    //           width: "41.4vw",
    //           left: "46.2vw",
    //           top: "69.8vw",
    //         }}
    //
    //       />
    //       <div
    //         className="absolute glass-tty"
    //         style={{
    //           left: "4.5vw",
    //           top: "168vw",
    //           fontSize: "9.26vw",
    //         }}
    //       >
    //         NFO #{tokenId}
    //       </div>
    //       <div
    //         className="absolute text-xs"
    //         style={{
    //           left: "4.5vw",
    //           top: "188vw",
    //           fontSize: "2.6vw",
    //         }}
    //       >
    //         {contract}
    //       </div>
    //       <div
    //         className="absolute"
    //         style={{
    //           left: "4.3vw",
    //           top: "202vw",
    //           fontSize: "3.7vw",
    //         }}
    //       >
    //         {owner}
    //       </div>
    //       <img
    //         onLoad={qrcodeLoaded}
    //         className="absolute"
    //         src={qrCode}
    //         style={{
    //           width: "18.518vw",
    //           right: "2.6vw",
    //           bottom: "2.6vw",
    //         }}
    //       />
    //     </div>
    //   </div>
    // </div>
  );
}
