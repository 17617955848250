import {Image, Layer, Stage} from "react-konva";
import {useEffect, useRef, useState} from "react";
import Konva from "konva";
import HammerComponent from "react-hammerjs";
import Enter from "./Enter";

export default function FlatScene(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // const isWeixin = urlParams.get('referral') === 'mai';
    const isWeixin = true;

    const videoTop = useRef();
    const videoBottom = useRef();
    let animBottom = useRef();
    let anim = useRef();
    const vidSpoiler = useRef();
    const vidSpoiler2 = useRef();
    const vidSpoiler3 = useRef();

    const scale = isWeixin ? window.innerWidth / 498 :  (window.innerHeight) / 498;
    const imgWindowHeight = 498;
    const imgLength = 4093;
    const moonLength = 1494;
    const imgClientHeight = imgLength * scale; // 屏幕上实际长度
    // const imgWindowWidth = window.innerWidth / scale; // 图片上实际长度


    const spoilerStart = 2272;
    const oreoLeftX = 2820;
    const oreoRightX = 3082;

    const initialState = {
        startX: 0,
        startY: 0,
        x: 0,
        y: 0,
        rotateStart: 0,
        rotate: 0
    };


    const videoScrollWidth = isWeixin ? imgWindowHeight : imgLength;
    const videoScrollHeight = isWeixin ? imgLength : imgWindowHeight;
    const moonWidth = isWeixin ? imgWindowHeight : moonLength;
    const moonHeight = isWeixin ? moonLength : imgWindowHeight;
    const [touchState, setTouch] = useState(initialState);
    const [capture, setCapture] = useState(false);
    const [offset, setOffset] = useState(0);
    const [entered, setEntered] = useState(false);
    //scroll
    const [topX, setTopX] = useState(0);
    const [topY, setTopY] = useState(0);
    const [bottomX, setBottomX] = useState(0);
    const [bottomY, setBottomY] = useState(0);
    //moon
    const [moonX, setMoonX] = useState(0);
    const [moonY, setMoonY] = useState(0);
    const [moon2X, setMoon2X] = useState(0);
    const [moon2Y, setMoon2Y] = useState(0);

    //loaded
    const [loaded, setLoaded] = useState(false);
    const [oreoFound, setFound] = useState(false);
    const [oreoTriggered, setTriggered] = useState(false);

    function EuclideanModulo (t, n){
        return (((t-n)%(2*n) + 2*n)) % (2 * n) - n;
    }

    useEffect(() => {
        let x = EuclideanModulo(touchState.rotate, imgClientHeight);
        setOffset(x);
        if(isWeixin){
            setTopY(EuclideanModulo(offset + imgClientHeight, imgClientHeight));
            setBottomY(offset);
            setMoon2Y(EuclideanModulo(offset + imgClientHeight, imgClientHeight) + spoilerStart * scale);
            setMoonY(EuclideanModulo(offset, imgClientHeight) + spoilerStart * scale);
        }else{
            setTopX(EuclideanModulo(offset + imgClientHeight, imgClientHeight));
            setBottomX(offset);
            setMoonX(EuclideanModulo(offset, imgClientHeight) + spoilerStart * scale);
            setMoon2X(EuclideanModulo(offset + imgClientHeight, imgClientHeight) + spoilerStart * scale);
        }
    })

    const [video] = useState(() => {
        let vid = document.createElement('video');
        let source1 = document.createElement('source');
        let sr1 = isWeixin ? "https://elements.cocafe.co/nfo/animations/ep-1/scroll_vertical.webm" : "https://elements.cocafe.co/nfo/animations/ep-1/scroll.webm";
        source1.setAttribute("src",sr1);
        source1.setAttribute("type","video/webm");
        vid.appendChild(source1);
        let source2 = document.createElement('source');
        let sr2 = isWeixin ? "https://elements.cocafe.co/nfo/animations/ep-1/scroll_vertical.mp4" : "https://elements.cocafe.co/nfo/animations/ep-1/scroll.mp4";
        source2.setAttribute("src",sr2);
        source2.setAttribute("type","video/mp4");
        vid.appendChild(source2);
        vid.muted = true;
        vid.loop = true;
        vid.autoplay = true;
        vid.crossOrigin = "anonymous";
        vid.playsInline = true;
        vid.preload = "auto";
        vid.addEventListener('loadedmetadata', (e) => {
            if(vid.readyState >= 1){
                setTimeout(() => {
                    setLoaded(true);
                },5000)
            }
        });

        if(window.navigator.userAgent.indexOf("iPhone") > -1 && isWeixin){
            setTimeout(() => {
                setLoaded(true);
            },5000)
        }

        return vid;
    });

    const [spoiler,setSpoiler] = useState(() => {
        let vid = document.createElement('video');
        let source1 = document.createElement('source');
        let sr1 = isWeixin ? "https://elements.cocafe.co/nfo/animations/ep-1/moon_vertical.webm" : "https://elements.cocafe.co/nfo/animations/ep-1/moon.webm";
        source1.setAttribute("src",sr1);
        source1.setAttribute("type","video/webm");
        vid.appendChild(source1);
        let source2 = document.createElement('source');
        let sr2 = isWeixin ? "https://elements.cocafe.co/nfo/animations/ep-1/moon_vertical.mp4" : "https://elements.cocafe.co/nfo/animations/ep-1/moon.mp4"
        source2.setAttribute("src",sr2);
        source2.setAttribute("type","video/mp4");
        vid.appendChild(source2);
        vid.muted = true;
        vid.loop = false;
        vid.autoplay = true;
        vid.crossOrigin = "anonymous";
        vid.playsInline = true;
        vid.preload = "auto";
        vid.addEventListener('loadedmetadata', (e) => {
            console.log(vid.readyState);
            // console.log(vid.videoHeight);
            // console.log(vid.videoWidth);

        });
        return vid;
    });

    useEffect(() => {
        animBottom.current = new Konva.Animation(
            () => {
                videoTop.current.image(video);
                videoBottom.current.image(video);
                // vidSpoiler3.current.image(spoiler);
            }, videoBottom.current.getLayer());
        // return () => anim.current.stop();
    })



    const handleStart = (e) => {
        if(video.currentTime === 0 && video.paused && loaded) {
            setEntered(true);
            // setTimeout(() => {setEntered(true)},500);
            video.play();
            spoiler.play();
            setTimeout(() => {spoiler.pause()},500);
            animBottom.current.start();
        }
        if(entered){
            setCapture(true);
            let startX = (e.touches ? e.touches[0]: e).clientX;
            let startY = (e.touches ? e.touches[0]: e).clientY;
            setTouch((preState) => ({...preState,['startX']:startX, ['startY']: startY}))
        }

    }

    const handleTouchMove = (e) => {
        if (capture) {
            let x = isWeixin ? (e.touches ? e.touches[0]: e).clientY - touchState.startY :(e.touches ? e.touches[0]: e).clientX - touchState.startX;
            let rotate = (touchState.rotateStart + touchState.x * 2);
            setTouch((preState) => ({...preState,['x']:x, ['rotate']: rotate}))
        }

    }

    const handleTouchEnd = (e) => {
        if (capture) {
            setTouch((preState) => ({...preState,['rotateStart']:touchState.rotate, ['x']: 0}))
            setCapture(false);
        }

    }
    const handleClick = (e) => {
        const imgX = ((e.clientX - offset + imgClientHeight) % imgClientHeight) / scale;
        if(video.currentTime === 0 && video.paused && loaded) {
            video.play();
            setEntered(true);
        }

    }

    const handleTap = (e) => {
        let current = isWeixin ? e.center.y : e.center.x;
        const posX = ((current - offset + imgClientHeight) % imgClientHeight) / scale;
        if (posX > oreoLeftX && posX < oreoRightX) {
            if(!oreoFound) {
                spoiler.pause();
                spoiler.currentTime = 0.5;
                spoiler.play();
                setFound(true);
                anim.current = new Konva.Animation(
                    () => {
                        vidSpoiler2.current.image(spoiler);
                        vidSpoiler.current.image(spoiler)
                    }, videoBottom.current.getLayer());
                // console.log('clicked');
                // setTriggered(true);
                // console.log(spoiler.currentTime);

                anim.current.start();
            }
        }
    }

    return (
        <HammerComponent
            onTap={handleTap}>
            <div onClick={handleClick}
            onTouchStart={handleStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onPointerDown={handleStart}
            onPointerMove={handleTouchMove}
            onPointerUp={handleTouchEnd}
        >
                {!entered && <Enter loaded={loaded}/>}

            <Stage width={window.innerWidth} height={window.innerHeight}>
                <Layer>
                    <Image ref={videoTop} x={topX} y={topY} width={videoScrollWidth} height={videoScrollHeight} scale={{x: scale, y:scale}}  />
                    <Image ref={videoBottom} x={bottomX} y={bottomY} width={videoScrollWidth} height={videoScrollHeight} scale={{x: scale,y:scale}}/>
                    <Image ref={vidSpoiler} x={moonX} y={moonY} width={moonWidth} height={moonHeight} scale={{x: scale, y:scale}} />
                    <Image ref={vidSpoiler2} x={moon2X} y={moon2Y} width={moonWidth} height={moonHeight} scale={{x: scale, y:scale}} />
                </Layer>
            </Stage>
            </div>
        </HammerComponent>

    );

}